<template>
  <b-form-group
    label="Yönetim"
    label-for="management"
  >
    <v-select
      id="management"
      v-model="employee.management"
      placeholder="Yönetim"
      label="title"
      :options="options"
      :reduce="options => options.id"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Management',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      options: [
        {
          id: '1', title: 'Evet',
        },
        {
          id: '0', title: 'Hayır',
        },
      ],
    }
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
