<template>
  <b-form-group
    :label="title"
    label-for="email"
  >
    <v-select
      id="email"
      v-model="employee.email"
      :disabled="isDisabled"
      :placeholder="title"
      label="title"
      :options="options"
      :reduce="options => options.id"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Email',
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          id: '1', title: 'Evet',
        },
        {
          id: '0', title: 'Hayır',
        },
      ],
    }
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
