<template>
  <b-form-group
    label="İşten Ayrılma Tarihi"
    label-for="edate"
  >
    <b-input-group>
      <b-form-datepicker
        id="edate"
        v-model="employee.edate"
        v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
      <template #append>
        <b-button
          variant="danger"
          @click="employee.edate= null"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormDatepicker, BInputGroup, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Edate',
  components: {
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BButton,
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
}
</script>

<style scoped></style>
